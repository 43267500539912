#health{
  padding-top: 120px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  width: 1400px;
  overflow: hidden;

  .health-title {
    position: relative;
    font-size: 27px;
    color: #151515;
    line-height: 41px;
    margin-bottom: 72px;
    &::after{
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 2px;
      background: #EFB200;
    }
  }
  
    
  .health-desc{
    font-size: 18px;
    font-weight: 300;
    color: #151515;
    line-height: 40px;
   
    &:last-child{
      margin-bottom: 57px;
    }
  }

  .features{
    width: 1198px;
    height: 566px;
    background: url(../asset/feature-bg.png);
    background-size: contain;
    margin-top: 50px;
    display: flex;
    .item{
      width: 350px;
      margin-left: 32px;
      .title{
        font-size: 24px;
        color: #151515;
        line-height: 36px;
        margin-bottom: 20px;
        text-align: left;
      }

      .desc{
        font-size: 14px;
        color: #151515;
        line-height: 30px;
        width: 225px;
        text-align: justify;
      }
     
      &:first-child{
        margin-top: 333px;
      }

      &:nth-child(2){
        margin-top: 79px;
      }

      &:nth-child(3){
        margin-top: 406px;
      }

      &:nth-child(4){
        margin-top: 42px;
      }
    }
  }
}