#service{
  padding-top: 120px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  width: 1400px;
  overflow: hidden;

  .service-desc{
    font-size: 18px;
    font-weight: 300;
    color: #151515;
    line-height: 40px;
    &:last-child{
      margin-bottom: 57px;
    }
  }

  .features {
    display: flex;
    justify-content: center;
    .feature-block{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 125px;
  
      &:last-child{
        margin-right: 0;
      }
  
      .icon-block{
        width: 106px;
        height: 106px;
        background: #FFFFFF;
        border: 1px solid #EFB200;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        margin-bottom: 62px;
      }
  
      .desc{
        position: relative;
        font-size: 16px;
        font-weight: 500;
        color: #151515;
        line-height: 24px;
        &::after{
          content: "";
          width: 70px;
          height: 3px;
          background: #EFB200;
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .detail{
    width: 1200px;
    margin-left: 100px;
    display: flex;

    &:first-child{
      margin-top:100px;
      .pic{
        width: 661px;
        height: 510px;
      }

      .icon{     
        margin-top: 139px;
      }

      .title{
        margin-top: 139px;
      }

      .desc{
        margin-right: 187px;
      }
    }

    &:nth-child(2){
      margin-top: 175px;
     
      .icon{     
        margin-top: 143px;
      }

      .title{
        margin-top: 143px;
      }

      .desc{
        margin-right: 65px;
      }
    }

    &:nth-child(3){
      .icon{     
        margin-top: 197px;
      }

      .title{
        margin-top: 197px;
      }
    
      .rect{
        margin-top: 138px;
        margin-left: 20px;
        width: 74px;
        height: 296px;
        background: url(../asset/rect.png);
        background-size: cover;
      }
    }

    .icon{
      font-size: 100px;
    }

    .title{
      font-size: 24px;
      font-weight: 400;
      color: #151515;
      line-height: 36px;
      margin-bottom: 13px;
      text-align: left;
    }

    .desc{
      width: 276px;
      font-size: 14px;
      font-weight: 300;
      color: #151515;
      line-height: 30px;
      text-align: left;
    }

    .bg2{
      width: 711px;
      height: 499px;
      background: url(../asset/detail2.png);
    }

    .detail2-list{
      margin-top: 22px;
      margin-left: 30px;
      .item{
        text-align: left;

        &:nth-child(n+3){
          .item-desc{
            display: flex;
            .item-desc-line{
              &:first-child{
                margin-right: 100px;
              }
            }
          }
        }
        .item-title{
          font-size: 16px;
          font-weight: 400;
          color: #151515;
          line-height: 24px;
          margin-bottom: 14px;
        }
        .item-desc{
          font-size: 14px;
          font-weight: 300;
          color: #151515;
          line-height: 24px;
          margin-bottom: 55px;
        }
      }
    }
   
    .detail2-list2{
      display: flex;
      margin-top: 64px;
      margin-left: 70px;
      .item{
        width: 229px;
        height: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #151515;
        line-height: 24px;
        &:first-child{
          margin-right: 135px;
        }
      }
    }

    .detail3-list{
      display: flex;
      margin-top: 211px;
      .item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:last-child{
          .pic{
            margin-right: 0;
          }
        }
        .pic{
          width: 50px;
          height: 50px;
          margin-bottom: 8px;
          margin-right: 142px;
        }
        .title{
          margin-top: 0;
          margin-bottom: 9px;
          font-size: 16px;
          font-weight: 400;
          color: #151515;
          line-height: 24px;
          width: auto;
        }
        .desc{
          width: auto;
          font-size: 14px;
          color: #151515;
          line-height: 24px
        }
      }
    }

  }

}

