@keyframes bounceInRight {
  0% {
     opacity: 0;
     transform: translateX(2000px);
  }
  60% {
     opacity: 1;
     transform: translateX(-30px);
  }
  80% {
     transform: translateX(10px);
  }
  100% {
    opacity: 1;
     transform: translateX(0);
  }
} 


.bounce {
  animation: bounceInRight 2s;
  animation-fill-mode: both;
}

.App {
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  
  .App-header {
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: white;
    z-index: 3;
    padding: 20px;

    .App-logo {
      width: 71px;
      height: 71px;
      pointer-events: none;
      margin-left: 60px;  
    }

    .nav-list{
      margin-right: 100px;  
      .link{
        margin-left: 40px;  
        color: white;
        font-size: 18px;
        text-decoration: none;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  

  .section{
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 1400px;
    overflow: hidden;

    .section-title{
      position: relative;
      font-size: 27px;
      color: #151515;
      line-height: 41px;
      margin-bottom: 72px;
      &::after{
        content: "";
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 70px;
        height: 2px;
        background: #EFB200;
      }
    }
  }

  #company{
    position: relative;
    width: 100%;
    background: url(./asset/bg.png);
    background-size: cover;
    .pic{
      width: 674px;
      height: 93px;
      position: absolute;
      left: 50%;
      top: 560px;
      transform: translateX(-50%);
      background: url(./asset/slogan.png);
      background-size: cover;
    }
  }

  #about{
    flex-direction: row;
    justify-content: center;
    .about-title{
      font-size: 27px;
      color: #151515;
      line-height: 41px;
      margin-top: 16px;
      margin-bottom: 53px;
      position: relative;
      &::after{
        content: "";
        display: block;
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 52px;
        height: 2px;
        background: #EFB200;
      }
    }
    .about-area{
      text-align: left;
      width: 572px;
    }
    .about-desc{
      font-size: 16px;
      font-weight: 500;
      color: #151515;
      line-height: 36px;
      margin-bottom: 40px;
    }
    
    .mobile-pic{
      width: 642px;
      height: 630px;
      opacity: 0;
    }
  }

  #friend{
    .section-title{
      margin-bottom: 132px;
    }
    padding-bottom: 106px;
    .pic{
      width:1204px;
      height:133px;
    }
  }

  #join{
    width: 100%;
    background: #0F0F0F;
    color: #fff;
    padding-bottom: 112px;
    .section-title{
      color: #fff;
    }
    .join-desc{
      width: 1241px;
      height: 69px;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 40px;
      margin-bottom: 16px;
      opacity: 0.8;
    }
    .join-list{
      width: 1400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .item{ 
      display: flex;
      align-items: center;
      text-align: left;
      margin-left: 87px;
      font-size: 14px;
      .desc{
        font-size: 16px;
        color: #FFFFFF;
        line-height: 40px;
        margin-left: 30px;
        opacity: 0.8;
      }
    }
  }
    
}






