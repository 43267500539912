#helper{
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    width: 1400px;
    overflow: hidden;
    padding-top: 120px;
  
    .helper-title {
      position: relative;
      font-size: 27px;
      color: #151515;
      line-height: 41px;
      margin-bottom: 150px;
      &::after{
        content: "";
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 70px;
        height: 2px;
        background: #EFB200;
      }
    }

    .features{
        display: flex;
        margin-bottom: 52px;
        .item {
            width: 378px;
            height: 563px;
            background: #FFFFFF;
            border-radius: 56px;
            border: 1px solid #DDDDDD;
            margin-right: 36px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:last-child{
                margin-right: 0;
                .desc{
                    width: 227px;
                    text-align: center;
                }
            }

            .pic{
                width: 378px;
                height: 297px;
            }

            .title{
                margin-top: 54px;
                margin-bottom: 32px;
                font-size: 24px;
                color: #151515;
                line-height: 36px;
            }


            .desc{
                width: 312px;
                height: 107px;
                font-size: 14px;
                font-weight: 300;
                color: #151515;
                line-height: 30px;
            }

        }
    }

    .list{
        display: flex;
        flex-wrap: wrap;
        margin: 0 128px 0;
        overflow: hidden;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 284px;
            margin-bottom: 113px;
            &:nth-child(3n){
                margin-right: 0;
            }
            &:nth-child(n+3){
                margin-bottom: 93px;
            }

            .pic{
                width: 192px;
                height: 261px;
                margin-bottom: 23px;
            }

            .title{
                font-size: 24px;
                color: #151515;
                line-height: 36px;
            }
        }
    }
}